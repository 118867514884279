import Head from "next/head";
import Searchbar from "../components/searchbar/searchbar";
import { useRouter } from "next/router";
import PageFooter from "../components/footer/page-footer";
import PageNavbar from "../components/navbar/navbar";
import styles from './app.module.css';
import { NavBarMobile } from "../components/navbar-mobile/navbar-mobile";
import cherryblossomLogoWhite from './../public/CherryLogoWhite.png';
import cherryBlossomLogoPink from './../public/CherryLogoPink.png';
import CartHandler from '../utils/shopping-cart-handler';
import Cart from "../components/cart/cart";
export default function MyApp({ Component, pageProps }: {Component: any, pageProps: any}) {
  const router = useRouter();
  const handleClick = (event: any) => {
    event.stopPropagation();
    router.push('/');
  }
  if (typeof document !== 'undefined') {
    CartHandler.init(document);
  }


  return (
	
	<>
    <Head>
      <title>Cherryblossom - Alle dine favorittprodukter samlet.</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" /> 
      <meta name="tradetracker-site-verification" content="79b69e1bea3be94c1a7780575bfe26d19168142b" />
      <meta name="keywords" content="kosmetikk, shampoo, balmsam, hudkrem, nattkrem, sminke, makeup"></meta>
      <meta name="description" content="Cherryblossom har samlet et stort utvalg skjønnhetsprodukter fra en rekke produsenter og leverendører slik at du skal kunne få den beste prisen." />
      <meta name="og:title" content=">Cherryblossom - Alle dine favorittprodukter samplet." />
      <meta name="og:description" content="Cherryblossom har samlet et stort utvalg skjønnhetsprodukter fra en rekke produsenter og leverendører slik at du skal kunne få den beste prisen." />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Cherryblossom" />
      <link rel="canonical" href="https://cherryblossom.no" />
      <link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="index" href="https://cherryblossom.no" />
      <script type="application/ld+json" dangerouslySetInnerHTML={
      {__html: `{
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": "https://cherryblossom.no/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://cherryblossom.no/sok?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }`}} />
    </Head>
    <div className={styles.headerWrapper}> 
      <header className={styles.header}>
          <div className={styles.headerInner}>
            <img src={cherryblossomLogoWhite.src} alt="Cherryblossom logo" style={{height: '24px'}} />
            <div style={{lineHeight: '18px', marginLeft: '8px', width: '100%'}} onClick={handleClick}>
              <span style={{fontSize: '18px', fontWeight: 600}}>Cherryblossom.no</span><br/>
              <span style={{fontSize: '13px', fontFamily: 'sans-serif'}}>Dine favoritter samlet på en plass.</span>
            </div>
            <div className="showInMobile showOnPad" style={{overflow: 'visible'}}><Cart /></div>
          </div>
        <PageNavbar/>
        <span className="hideInMobile hideOnPad"><Cart /></span>
      </header>
    </div>      
    <section className={styles.searchBarWrapper}>
        <Searchbar/>
    </section>
    <div className={router.pathname.toLowerCase().indexOf('sok') == -1 ? styles.mainWrapper : ''}>
      <Component {...pageProps} />
    </div>
    <NavBarMobile/>
    <PageFooter/> 
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');
        :root{
          --primary-color: #e5205e;
        }
        html,
        body {
		      padding: 0;
		      margin: 0;
		      font-size: 16px;
          background: var(--primary-color);
          font-family: 'Open Sans', sans-serif;
		      color: #fff;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;   
          scroll-behavior: smooth;       
        }
        a {
          color: white;
        }
        a:visited{
          color: white;
        }           
        .pinkHeader {
          background: #db3ca9;
          color: white;
          padding: 8px;
        }
        #__next {
          height: inherit;
        }
        * {
          box-sizing: border-box;
        }
        .showOnPad {
          display: none;
        }
        .hideInMobile {
          display: block;
        }
        .showInMobile {
          display: none;
        }          
        @media only screen and (max-width: 600px) {
          .hideInMobile {
            display: none;
          }
          .showInMobile {
            display: block;
            overflow: hidden;
            text-align: center;
          }            
        }
        @media only screen and (min-width: 600px) and (max-width: 1200px) {
          .hideOnPad {
            display: none;
          }
          .showOnPad {
            display: block;
          }          
        }      
      `}</style>
    </>
  )
}