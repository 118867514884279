import { useEffect, useState } from 'react';
import styles from './navbar-mobile.module.css';
import { useRouter } from 'next/router';

export function NavBarMobile() {
	const [showMenu, setShowMenu] = useState(false);
	const router = useRouter();
	useEffect(() => { setShowMenu(false); }, [router]);
	return (
		<div className="showInMobile">
			<div className={styles.mobileNavContainer} style={{ display: `${showMenu ? 'block' : 'none'}` }}>
				<nav className={styles.navbar}>
					<div style={{ fontSize: '24px', fontWeight: 600, marginTop: '8px' }}>Hårpleie</div>
					<ul>
						<li><a href="/sok?q=Shampoo">Shampoo</a></li>
						<li><a href="/sok?q=Balsam">Balsam</a></li>
						<li><a href="/sok?q=Hårkur">Hårkur</a></li>
						<li><a href="/sok?q=Hårolje">Hårolje</a></li>
						<li><a href="/sok?q=Hårfarge">Hårfarge</a></li>
						<li><a href="/sok?q=Rettetang">Rettetang</a></li>
						<li><a href="/sok?q=Krølltang">Krølltang</a></li>
					</ul>

					<div style={{ fontSize: '24px', fontWeight: 600, marginTop: '8px' }}>Hudpleie</div>
					<ul>
						<li><a href="/sok?q=Ansiktsrens">Ansiktsrens</a></li>
						<li><a href="/sok?q=Serum">Serum</a></li>
						<li><a href="/sok?q=Solkrem">Solkrem</a></li>
						<li><a href="/sok?q=Fuktighetskrem">Fuktighetskrem</a></li>
						<li><a href="/sok?q=Eksfoliator">Eksfoliator</a></li>
					</ul>

					<div style={{ fontSize: '24px', fontWeight: 600, marginTop: '8px' }}>Sminke</div>
					<ul>
						<li><a href="/sok?q=Øyenskygge">Øyenskygge</a></li>
						<li><a href="/sok?q=Mascara">Mascara</a></li>
						<li><a href="/sok?q=Eyeliner">Eyeliner</a></li>
						<li><a href="/sok?q=Leppestift">Leppestift</a></li>
						<li><a href="/sok?q=Lipgloss">Lipgloss</a></li>
						<li><a href="/sok?q=Foundation">Foundation</a></li>
						<li><a href="/sok?q=Concealer">Concealer</a></li>
					</ul>
					<div style={{ fontSize: '24px', fontWeight: 600, marginTop: '8px' }}>For hann</div>
					<ul>
						<li><a href="/sok?q=Skjeggolje">Skjeggolje</a></li>
						<li><a href="/sok?q=Mr Bear Family">Mr Bear Family</a></li>
					</ul>
					<div style={{ fontSize: '24px', fontWeight: 600, marginTop: '8px' }}>Kropp</div>
					<ul>
						<li><a href="/sok?q=Deodorant">Deodorant</a></li>
						<li><a href="/sok?q=Hårfjerning">Hårfjerning</a></li>
						<li><a href="/sok?q=Hudkrem">Hudkrem</a></li>
					</ul>

				</nav>
			</div>
			<button className={styles.fab} onClick={() => {
				setShowMenu(!showMenu);
			}}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
					<title>menu</title>
					{!showMenu ?
						<path fill="#e5205e" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
						:
						<path fill="#e5205e" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
					}
				</svg>
			</button>
		</div>

	);
}