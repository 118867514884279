import styles from './navbar.module.css';
import NavItem from './navitem';
export default function PageNavbar() {
	return (
		<nav className={styles.navbar}>
			<ul>
				<NavItem title="Hårpleie" subitems={["Shampoo", "Balsam", "Hårkur", "Hårolje", "Hårfarge","Rettetang","Krølltang"]}></NavItem>
				<NavItem title="Hudpleie" subitems={["Ansiktsrens","Serum", "Solkrem", "Fuktighetskrem","Eksfoliator"]}></NavItem>
				<NavItem title="Sminke" subitems={["Øyenskygge", "Mascara", "Eyeliner","Leppestift","Lipgloss", "Foundation","Concealer"]}></NavItem>
				<NavItem title="For han" subitems={["Skjeggolje", "Mr Bear Family"]}></NavItem>
				<NavItem title="Kropp" subitems={["Deodorant", "Hårfjerning", "Hudkrem"]}></NavItem>
			</ul>
		</nav>
	)
}