import { useRouter } from 'next/router';
import styles from './searchbar.module.css';
import { useEffect, useState } from 'react';
import logo from './../../public/CherryLogoWhite.png'
import searchIcon from './../../public/search-icon-pink.png'
export default function Searchbar() {
	const router = useRouter();
	const {q} = router.query;
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		if (q != undefined) {
			setSearchValue(q as string);
		} else {
			setSearchValue('');
		}
		
	},[q]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (searchValue && searchValue.length > 1){
			router.push(`/sok?q=${searchValue}`);
		}
	}

	return (
		<div className={styles.searchbarContainer}>
			<div className={styles.textWrapper}>
				<img src={logo.src} style={{height: '42px', float: 'left'}} alt="Logo"/>
				<div style={{fontSize: '22px', fontWeight: 500, paddingLeft: '16px'}}>
					Det største utvalget i kosmetikk og skjønnhetsprodukter
					<div style={{fontSize: '14px', fontWeight: 500}}>Søk i over 20 000 produkter fra de største norske nettbutikkene.</div>
				</div>
			</div>
			<form onSubmit={handleSubmit} style={{position: 'relative'}}>
				<label htmlFor="searchField">Søk i over 10 000 produkter</label>
				<input value={searchValue} 
					style={{
						backgroundImage: `url(${searchIcon.src})`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: '98%',
						backgroundSize: '18px'
					}}
					onChange={(e: any) => {
						setSearchValue(e.target.value)
					}}
					autoComplete="off" id="searchField" name="searchField" className={styles.searchbar} placeholder="Shampoo, Serum, Foundation, Makeup..." type="text"/>
			</form>
		</div>
	)
}