import Link from "next/link";
import styles from './navbar.module.css';

type SubItemObj = {label: string, url: string};
export default function NavItem(props: {title: string, subitems: unknown[]}) {
	return (

		<li key={props.title}>
			<span>{props.title}</span>
			<ul className={styles.subMenu}>
				{props.subitems.map((el, i: number) => {
					if (typeof el == 'object') {
						const _el = el as SubItemObj;
						return <li key={`${_el}_${i.toString()}`}><Link href={`${_el.url}`} passHref>{_el.label}</Link></li>
					}
					const _el = el as string;
					return <li key={`${_el}_${i.toString()}`}><Link href={`/sok?q=${_el}`} passHref>{_el}</Link></li>
				})}
			</ul>
		</li>

	)
}